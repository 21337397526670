import { css } from 'styled-components';

const devices = {
  mobile: '(min-width: 1px) and (max-width: 639px)',
  phablet: '(min-width: 640px) and (max-width: 767px)',
  tablet: 'screen and (max-width: 1024px)',
  desktop: '(min-device-width: 1025px) and (-webkit-min-device-pixel-ratio: 1)',
  hd_desktop: 'screen and (min-width: 1440px)',
};

export default devices;

const BreakPoints = {
  xs: 320,
  xsm: 375,
  xmd: 480,
  sm: 768,
  md: 1024,
  lg: 1280,
  hd: 1440,
  xhd: 1920,
};

export const media = Object.keys(BreakPoints).reduce((accumulator, label) => {
  const pxSize = BreakPoints[label];
  accumulator[label] = (arg, ...args) => css`
    @media (min-width: ${pxSize}px) {
      ${css(arg, ...args)};
    }
  `;
  return accumulator;
}, {});
